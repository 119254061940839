.domain-transfer {
  .product-details {
    form {
      margin-bottom: var(--gu-3);
    }
  }

  .product-title {
    margin-bottom: var(--gu-3);
  }

  @media (min-width: 1200px) {
    .product-details {
      padding: 0 var(--gu-7); // plus the 5 from .card makes 12

      form {
        margin-bottom: var(--gu-6);
      }
    }
    .product-title {
      margin-bottom: var(--gu-6);
    }
  }

  .product-description {
    > ul {
      padding-left: 0;
      list-style-type: none;
      margin-bottom: var(--gu-10);

      [dir=rtl] & {
        padding-right: 0;
      }

      @media (min-width: 768px) {
        columns: 2;
        column-gap: var(--gu-12);

        > li {
          break-inside: avoid;
        }
      }
      li {
        margin-top: 0;
        margin-bottom: var(--gu-2);
      }
    }

    // the product disclaimer
    .text-muted {
      color: #333 !important;

      h5 {
        font-weight: bold;
      }

      ul {
        list-style-type: decimal;
        font-size: 0.9em;
      }
    }

  }
}
