/* main body content should always fill the available screen space */
body.ux-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  > .body-error {
    margin: var(--gu-8) 0;
  }
}

.topnav {
  .h3 {
    margin-bottom: 0.25em;
  }

  .h5,
  .subnav-mobile-heading {
    display: none;
  }

  /*
    This element causes a horizontal overflow scroll to appear.
    It appears that they are not used as there is an svg that acts as a caret icon.
    Looks like dead code that needs to be pruned.
  */
  .tray-toggle-caret {
    display: none !important;
  }
}

.secondarynav {
  // set background width based on container width and padding
  @function getWidth($container-width) {
    @return calc((100% - #{$container-width} + 20px) / 2);
  }
  &:before {
    @media (min-width: 992px) {
      width: getWidth('940px');
    }
    @media (min-width: 1200px) {
      width: getWidth('1140px');
    }
    @media (min-width: 1720px) {
      width: getWidth('1640px');
    }
  }

  &-items li {
    vertical-align: middle;
  }

  @media (min-width: 992px) {
    .secondarynav-items li:last-child {
      padding-right: 0;
    }
  }
}

.storefront-spinner {
  position: relative;

  &.storefront-spinner--overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#fff, 0.85);

    .ux-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.storefront-spinner-container {
  text-align: center;
  line-height: 36em;
  line-height: 50vh;
  line-height: calc(100vh - 20em);
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.product-icon {
  max-width: 100%;
}

#redemption-search {
  display: flex;
  width: 100%;
  color: black;
}

.search-form {
  display: flex;
  width: 100%;
  margin: 1.5em auto 0;

  &-input {
    flex-grow: 1;
    min-width: 0; // allows input to collapse and prevent overflow
    width: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    [dir='ltr'] & {
      border-right: none;
    }

    [dir='rtl'] & {
      border-left: none;
    }
  }

  &-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    white-space: nowrap;
    min-width: unset;
  }
}

.grecaptcha-badge {
  z-index: 1;
}

.captcha-fallback {
  max-width: 220px;
  margin: 0 0 1em;

  &-wrapper {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 20.454545%;
  }

  &-controls {
    display: flex;
    margin: 1em 0 0;

    .req {
      padding: 0 0.25em;
    }
  }
}


.taxAndFeeDisclaimer {
  margin-top: auto; // pin to bottom
  font-size: 0.75rem;
  padding: 1em 0 0;

  p {
    font-size: inherit;
    margin: 0;
    font-style: italic;
    &:last-of-type {
      margin: 0 0 1em;
    }
  }
}

/* Vendor Overrides */
.ux-app .topnav .tray-dropdown {
  background-color: $ux-box-backgroundColor;
  color: $ux-box-foregroundColor;
  border-color: $ux-box-borderColor;
  border-style: solid;
  border-width: 1px 0;

  a, .h3 {
    color: $ux-box-foregroundColor !important;
  }
}

.reseller-footer .currency-selector-wrapper {
  right: 1rem !important;
  left: auto;
}

// TODO: remove when fixed upstream - overrides for svg colors
svg .svg-fill-primary-o {
  fill: $ux-actionChosen-backgroundColor !important;
};

@media (max-width: 991px) {
  .ux-pagetitle {
    padding-top: 0;
    padding-bottom: var(--gu-4);
  }
}

.tray-content {
  .close::before {
    position: relative;
    z-index: 9;
    font-size: var(--gu-3);
    padding: 0 1px;
  }
}

.ux-card.contactUs-sidebar-help {
  .card-block {
    a {
      white-space: normal;
    }
  }
}

.product-showMore,
.scroll-down-container {
  color: $ux-navigate-foregroundColor;
}

.scroll-down-container {
  align-items: center;
}