@mixin clear-based-on-direction {
  [dir=ltr] & {
    clear: left;
  }
  
  [dir=rtl] & {
    clear: right;
  }
}

.product-add-wrapper {
  margin-bottom: var(--gu-2);
}

@media (min-width: 768px) {
  .product-list {
    display: flex;
    justify-content: stretch;
    flex-flow: wrap;
    margin: 0 calc(0 - var(--gu-1));
    .product-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 var(--gu-1);
      &.flex-md-6 {
        flex: 0 0 50%;
      }
      .product-card {
        height: 100%;
      }
    }
  }
}

@media (min-width: 992px) {
  .product-list {
    .product-item {
      &.flex-lg-6 {
        flex: 0 0 50%;
      }
      &.flex-lg-4 {
        flex: 0 0 33.3333%;
      }
      &.flex-lg-3 {
        flex: 0 0 25%;
      }
    }
  }
}

@media (min-width: 1720px) {
  .product-list {
    .product-item {
      &.flex-xxl-6 {
        flex: 0 0 50%;
      }
      &.flex-xxl-4 {
        flex: 0 0 33.3333%;
      }
      &.flex-xxl-3 {
        flex: 0 0 25%;
      }
    }
  }
}
.product {
  &-header-container {
    $smallIconSize: var(--gu-8);
    $largeIconSize: var(--gu-12);

    position: relative;
    font-size: 1em;

    &.banner {
      padding: 0;
    }

    .product-icon {
      display: inline-block;
      vertical-align: middle;
      width: $smallIconSize;
      min-width: $smallIconSize;
      margin-right: var(--gu-3);

      [dir=rtl] & {
        margin-right: 0;
        margin-left: var(--gu-3);
      }
    }

    .product-icon-text {
      display: inline;
    }

    .headline-brand {
      display: inline-block;
      vertical-align: middle;
    }

    @media (min-width: 768px) {
      min-height: $largeIconSize;

      .product-icon {
        width: $largeIconSize;
        min-width: $largeIconSize;
      }

      &.has-description {
        .product-icon-text {
          display: block;
          overflow: hidden;
        }

        .product-icon {
          float: left;

          [dir=rtl] & {
            float: right;
          }
        }
      }
    }
  }

  &-header-description {
    clear: left;
    margin-top: var(--gu-1) !important;
    max-width: 100%;

    // any sibling elements should flow th same way
    + * {
      margin-top: var(--gu-1) !important;
      margin-bottom: 0;
    }

    .banner & {
      margin: 0 auto;
    }
    @media (min-width: 768px) {
      clear: none;
      max-width: 90ch;
    }
  }

  &-title {
    font-weight: var(--uxp-font-weight-bold);
  }

  &-price {
    margin-bottom: var(--gu-2);
  }

  &-amount {
    font-size: 1.25em;
  }

  &-item {
    &:first-child {
      @include clear-based-on-direction;
    }

    // Clearing the floats based on the new column layout. 
    // Screen break point: `xl` and above
    // Number of products: 5 / 6
    // Clear rule formula: Constant value (4th product)
    // Column layout: 3-column-layout
    // We apply the clear rule based on the html direction on the 4th product because
    // we are using a 3-column layout when there are 5 / 6 products so the 4th product
    // needs to clear the floats.
    // Number of products: >= 7
    // Clear rule formula: 4n + 1 (n >= 0)
    // Column layout: 4-column-layout
    // We apply the clear rule based on the html direction on the products that follow
    // the pattern 4n + 1 because we are using a 4-column layout when there are >= 7
    // products so every 4n + 1 (1, 5, 9, 13....) product needs to clear the floats.
    @media (min-width: 1720px) {
      &:nth-last-child(5):first-child ~ &:nth-child(4),
      &:nth-last-child(6):first-child ~ &:nth-child(4),
      &:nth-last-child(n + 7):first-child ~ &:nth-child(4n + 1) {
        @include clear-based-on-direction;
      }
    }

    // Clearing the floats based on the new column layout. 
    // Screen break point: `lg` and `md`
    // Number of products: >= 4
    // Clear rule formula: 3n + 1 (n >= 0)
    // Column layout: 3-column-layout
    // We apply the clear rule based on the html direction on the products that follow
    // the pattern 3n + 1 because we are using a 3-column layout when there are >= 4
    // products so every 3n + 1 (1, 4, 7, 10....) product needs to clear the floats.
    @media (min-width: 992px) and (max-width: 1719px) {
      &:nth-last-child(n + 4):first-child ~ &:nth-child(3n + 1) {
        @include clear-based-on-direction;
      }
    }

    @media (max-width: 991px) {
      &:not(:first-child):nth-child(odd) {
        @include clear-based-on-direction;
      }
    }
  }

  &-card {

    &.ux-card {
      .card-block {
        padding: 0;
      }

      .card-block-footer {
        margin-top: auto;
      }

      padding: var(--gu-2);
      margin-bottom: 16px; // matches the gap between .col gutters

      @media (min-width: 768px) {
        padding: var(--gu-3);
      }

      @media (min-width: 1200px) {
        padding: var(--gu-4);
      }
    }

    .btn .spinner-wrap {
      display: inline-block;
      margin-right: 6px;
    }

    &.domain-transfer .product-description h5 {
      margin-bottom: 0;
    }

    form {
      margin: 0 0 var(--gu-2);
    }
  }
  
  &-flag {
    font-size: 1.5em;
    padding-left: 0.5em;
  }

  &-details {
    .spin-center {
      height: 18px;
    }

    .product-description {
      ul,
      ul:last-child li,
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-description {
    ul {
      padding-left: 1.2rem; // align with left edge of content

      [dir=rtl] & {
        padding-right: 1.2rem;
        padding-left: 0;
      }
    }
  }

  &-disclaimers-text {
    margin-top: calc($ux-box-density * 4);
  }

  &-disclaimers-btn {
    margin-top: var(--gu-3);

    button {
      color: $ux-navigate-foregroundColor;
    }
  }
}

.products {
  @media (min-width: 768px) {
    &.microsoft-365,
    &.ssl {
      .product-title {
        min-height: var(--gu-8);
      }
      .product-description p:first-of-type {
        min-height: var(--gu-6);
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    &.ssl {
      .product-title {
        min-height: var(--gu-12);
      }
    }
  }
  .search-form {
    margin-top: 0;
    margin-bottom: 2em;
    .ux-search:not(.input-group) {
      flex: 1;
    }

    &.inline {
      margin-top: 1em;
    }
  }

  &--spinner {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    height: 72px;
    width: 72px;
  }

  .tab-filter {
    display: flex;
    justify-content: center;
    list-style: none;
    font-size: 1.125em;
    padding: 0;
    margin: 0 0 2em;
    li {
      margin: 0;
      border-bottom: 2px solid;
      text-align: center;
      width: 12.5em;
      max-width: 50%;
      padding-bottom: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      &.text-muted .text-muted {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      a, button {
        padding: 0;
        text-decoration: none;
        outline: 0;
        background-color: inherit;
        border: 0;
        cursor: pointer;
        display: block;
        width: 100%;
      }
    }
  }

  .vps-switch {
    margin-bottom: 3em;
  }
}

.mcx-wrapper {
  padding: 2em 0;
}

.disclaimer-modal-content {
  max-width: 40rem;
}