:root {
  // Grid Unit
  --gu: 8px;
  --gu-1: 8px;
  --gu-2: 16px;
  --gu-3: 24px;
  --gu-4: 32px;
  --gu-5: 40px;
  --gu-6: 48px;
  --gu-7: 56px;
  --gu-8: 64px;
  --gu-10: 80px;
  --gu-12: 96px;
}
