.domain-registration {
  .product-details {
    form {
      margin-bottom: var(--gu-3);
    }
  }

  .product-title {
    margin-bottom: var(--gu-3);
  }

  @media (min-width: 1200px) {
    .product-details {
      padding: 0 var(--gu-7); // plus the 5 from .card makes 12

      form {
        margin-bottom: var(--gu-6);
      }
    }
    .product-title {
      margin-bottom: var(--gu-6);
    }
  }

  .product-description {
    > ul {
      padding-left: 0;
      list-style-type: none;

      &:last-of-type {
        margin-bottom: var(--gu-10);
      }

      [dir=rtl] & {
        padding-right: 0;
      }

      @media (min-width: 768px) {
        columns: 2;
        column-gap: var(--gu-12);
      }

      li {
        margin-top: 0;
        margin-bottom: var(--gu-2);
      }
    }

    > p:first-of-type {
      margin-bottom: var(--gu-3);
      // mimic the heading with an .h4 style
      font-size: 1.423828125rem;
      line-height: 1.75rem;

      @media (min-width: 992px) {
        font-size: 1.601806640625rem;
        line-height: 2rem;
      }

      @media (min-width: 1200px) {
        margin-bottom: var(--gu-6);
      }
    }
  }

  .product-disclaimers {
    > ul {
      padding-left: 0;
      list-style-type: none;
      display: inline;
      font-size: 0.9em;

      [dir=rtl] & {
        padding-right: 0;
      }

      li {
        display: inline;
      }
    }

    // the disclaimer heading
    > p:first-of-type {
      display: inline;

      small {
        font-weight: bold;
      }
    }
  }
}
