@import '~@ux/intents/lib/index';

#banner .alert {
  margin: 0;
  padding-right: 2.5rem;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;

  button.close {
    top: 1.25rem;
    right: 1rem;
  }
}

.home {
  a, a:hover {
    text-decoration: none;
  }
  &-hero {
    padding: 100px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-color: $ux-figure-figureColor29;
    color: $ux-box-foregroundColor;

    h1 {
      color: inherit;
    }

    &-text {
      display: inline-block;
      padding: 2% 4%;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }

    .search-form {
      max-width: 600px;
      .ux-search:not(.input-group) {
        flex: 1;
      }
    }

    &-tld {
      .product-icon {
        display: none;
      }

      &-pricing span {
        display: block;
        line-height: 1.5em;
      }

      @media (min-width: 520px) {
        display: flex;
        justify-content: center;
        align-items: center;

        .product-icon {
          display: block;
          $size: 2.43em;
          width: $size;
          height: $size;

          svg {
            width: 100%;
          }
        }

        &-pricing {
          padding-left: 0.5em;
        }
      }
    }
  }

  &-hero-image {
    background-color: #fff;
  }

  &-products {
    background: #fff;

    &-product {
      padding: var(--gu-2);

      @media (min-width: 768px) {
        padding: var(--gu-3);
      }

      @media (min-width: 1200px) {
        padding: var(--gu-5);
      }

      h3 {
        margin: 0;
      }

      p {
        margin-top: var(--gu-1);
        margin-bottom: 0;
      }

      svg {
        display: block;
        width: 8rem;
        margin: 0 auto var(--gu-2);
      }
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: var(--gu-3) 0;
    }
  }

  &-contact {
    &-info {
      padding: 3em;
    }
  }

  &.super {
    .home-products {
      padding-top: 2em;
      background: unset;
    }

    @media (min-width: 768px) {
      .home-products {
        margin-top: -10em;
      }

      .home-hero {
        padding-bottom: 12em;
      }
    }
  }
}

.form-error,
.form-indicator {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.5rem;
  margin: 0;
}

.form-indicator {
  font-size: 0.875rem;
  right: 10px;
}

.forwarded-from .modal-header {
  padding-bottom: 0;
}

// Growl goes under the navbar otherwise
.ux-growl-shell {
  z-index: 10;
}