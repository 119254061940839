.contactUs {
  .ux-text-input-shell,
  .ux-select-dropdown,
  .form-group {
    position: relative;
    margin-bottom: var(--gu-3);
  }
  .ux-text.ux-text-feedback-critical[role="alert"] {
    position: absolute;
    right: 0;
    line-height: var(--uxText--lineHeight);
    margin: 0;
  }
  &-englishOnly {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    margin-top: -1.5rem;
  }

  &-sidebar {
    &-support {
      &-label,
      &-value {
        display: block;
      }
    }

    &-help {
      .btn {
        width: 100%;
      }
    }
  }

  &-interstitial {
    .btn {
      width: 100%;
    }
  }

  &-form {
    textarea {
      height: 8em;
      resize: none;
    }
  }
}
