.redemption {
  padding: 4rem 1rem 2rem;

  h1 {
    font-size: 1.5rem;
    line-height: 1.25em;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
}
